import React, { useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import PaginateList from "./PaginateList.view";
import { useQuery } from "@tanstack/react-query";
// import { withTranslation } from "react-i18next";

const PaginateListContainer = ({
  columns,
  data,
  hideColum,
  resize = true,
  refresh,
  resetRefresh,
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data: data,
    columns: columns, //columnsDef,
    enableColumnResizing: resize,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      // columnVisibility: hideColum || {},
      pagination,
      // totalDocs: dataQuery.data?.totalDocs ?? 0,
    },
    // debugTable: true,
  });

  return <>{data ? <PaginateList table={table} /> : ""}</>;
  // return <PaginateList table={table} />;
};

export default PaginateListContainer;
