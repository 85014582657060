import React from "react";
import EvalAllList from "../../../components/user/evaluation/evaluationAllList";
import Layout from "../../../components/layouts/verticalLayout";

const EvaluationListAllPage = (props) => {
  return <Layout body={<EvalAllList />} />;
};

EvaluationListAllPage.propTypes = {};

export default EvaluationListAllPage;
