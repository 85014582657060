import React, { useState } from "react";
import List from "../evaluationListAll";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
// import Select from "react-select";
import Filter from "../evaluationFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as EvaluationService from "../../../../services/EvaluationServices";
import { EVAL_STATUS } from "../../../../constants/index";
import * as XLSX from "xlsx";
import moment from "moment-timezone";

const queryClient = new QueryClient();

const EvalFilterListAllContainer = (props) => {
  const { t } = useTranslation();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.evaluation.titleList") },
  ];
  const [filters, setFilters] = useState(undefined);

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const exportToExcel = async () => {
    const filter = {
      filters,
      sort: "-date",
      exportXls: true,
    };
    EvaluationService.listToExport(filter).then((result) => {
      console.log(result);
      const data = result.docs.map((eva) => {
        const status = EVAL_STATUS.find((st) => st.code === eva.status);
        return {
          A: eva.year,
          B: `${eva.evaluated.name} ${eva.evaluated.surname}`,
          C: `${eva.evaluated.email}`,
          D: `${eva.evaluator.name} ${eva.evaluator.surname}`,
          E: t(status.label),
          // F: t(type.label),
          // G: move.variety.replace("PODRIDO", ""),
          // H: move.calibre === "CAS" || move.type === 4 ? "-" : move.calibre,
          // I: move.partidora ? "SI" : "NO",
          // J: move.user,
        };
      });
      data.unshift({
        A: "Año",
        B: "Evaluado",
        C: "Email del Evaluado",
        D: "Manager",
        E: "Estado",
        // F: "Tipo",
        // G: "Variedad",
        // H: "Calibre",
        // I: "¿Partiendo?",
        // J: "Operario",
      });
      const filename = `Evaluations_export_${moment(new Date()).format(
        "DDMMyyyyHHmm"
      )}`;
      const sheetName = "Listado de evaluaciones";
      var ws = XLSX.utils.json_to_sheet(data, {
        header: ["A", "B", "C", "D", "E"],
        skipHeader: true,
        UTC: true,
        dateNF: "dd-mm-yyyy hh:mm;@",
      });
      // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.evaluation.titleList")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11 text-center">
          <button
            type="button"
            className="btn btn-lg btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
    </>
  );
};

EvalFilterListAllContainer.propTypes = {};

export default EvalFilterListAllContainer;
