export const PAGE_SIZE_10 = 10;
export const CLEAN_AUTH = "CLEAN_AUTH";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const GET_PROFILE = "GET_PROFILE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const CLEAN_NAV = "CLEAN_NAV";
export const PREREGISTER_EMPLOYEE = "PREREGISTER_EMPLOYEE";

export const ROLE_EVALUATOR = "evaluator";
export const ROLE_EVALUATED = "evaluated";
export const ROLE_MANAGER = "manager";
export const ROLE_ADMIN = "admin";
export const ROLE_STAFF = "staff";

export const LANGUAGE_ES = {
  code: "es",
  label: "language.languages.es",
};
export const LANGUAGE_EN = {
  code: "en",
  label: "language.languages.en",
};
export const LANGUAGE_PT = {
  code: "pt",
  label: "language.languages.pt",
};

export const LANGUAGES = [LANGUAGE_ES, LANGUAGE_PT];

export const STATUS_OPEN = {
  code: "OPEN",
  label: "status.open",
};

export const STATUS_CLOSE = {
  code: "CLOSE",
  label: "status.close",
};

export const STATUS_BLOCKED = {
  code: "BLOCKED",
  label: "status.blocked",
};

export const STATUS = [STATUS_OPEN, STATUS_CLOSE, STATUS_BLOCKED];

export const EVAL_STATUS_PENDING = {
  code: "PENDING",
  label: "forms.evaluation.status.pending",
};

export const EVAL_STATUS_DRAFT = {
  code: "DRAFT",
  label: "forms.evaluation.status.draft",
};

export const EVAL_STATUS_CONFIRM = {
  code: "CONFIRM",
  label: "forms.evaluation.status.confirm",
};

export const EVAL_STATUS_TO_AUTO = {
  code: "TO_AUTO_EVAL",
  label: "forms.evaluation.status.toAuto",
};

export const EVAL_STATUS_AUTO_EVAL = {
  code: "AUTO_EVALUATED",
  label: "forms.evaluation.status.autoEval",
};

export const EVAL_STATUS_TO_EVAL = {
  code: "TO_EVAL",
  label: "forms.evaluation.status.toEval",
};

export const EVAL_STATUS_EVALUATED = {
  code: "EVALUATED",
  label: "forms.evaluation.status.evaluated",
};

export const EVAL_STATUS = [
  EVAL_STATUS_PENDING,
  EVAL_STATUS_DRAFT,
  EVAL_STATUS_CONFIRM,
  EVAL_STATUS_TO_AUTO,
  EVAL_STATUS_AUTO_EVAL,
  EVAL_STATUS_TO_EVAL,
  EVAL_STATUS_EVALUATED,
];
