import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ThTitle,
  TdCell,
  IconStatus,
  Option,
  ListOptions,
  ButtonAction,
  StatusContainer,
  IconStatusHead,
  IconContainer,
} from "./evaluationList.styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  EVAL_STATUS_PENDING,
  EVAL_STATUS_CONFIRM,
  EVAL_STATUS_DRAFT,
  EVAL_STATUS_EVALUATED,
  EVAL_STATUS_TO_EVAL,
  EVAL_STATUS_AUTO_EVAL,
  EVAL_STATUS_TO_AUTO,
} from "../../../../constants/index";

const EvaluationListView = (props) => {
  const { data, onConfirm } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const [data, setData] = useState(null);

  const renderEvaluated = () => {
    if (data.dataEvaluados && data.dataEvaluados.length > 0) {
      return data.dataEvaluados.map((dataEval, index) => {
        return (
          <div className="row" key={dataEval.uuid}>
            <TdCell
              $odd={index % 2 === 0}
              $first
              className="col-3 d-flex align-items-center"
            >{`${dataEval.name} ${dataEval.surname}`}</TdCell>
            <TdCell
              $odd={index % 2 === 0}
              className="col-4  d-flex align-items-center"
            >{`${dataEval.email}`}</TdCell>
            <TdCell $odd={index % 2 === 0} className="col-3">
              <StatusContainer className="row">
                <IconContainer className="col d-flex align-items-center justify-content-center">
                  {data.evaluations[dataEval.uuid].status ===
                  EVAL_STATUS_PENDING.code ? (
                    <IconStatus
                      $active
                      className="fa-solid fa-check"
                    ></IconStatus>
                  ) : (
                    <IconStatus className="fa-solid fa-check"></IconStatus>
                  )}
                </IconContainer>
                <IconContainer className="col d-flex align-items-center justify-content-center">
                  {data.evaluations[dataEval.uuid].status ===
                  EVAL_STATUS_DRAFT.code ? (
                    <IconStatus
                      $active
                      className="fa-solid fa-check"
                    ></IconStatus>
                  ) : (
                    <IconStatus className="fa-solid fa-check"></IconStatus>
                  )}
                </IconContainer>
                <IconContainer className="col d-flex align-items-center justify-content-center">
                  {data.evaluations[dataEval.uuid].status ===
                  EVAL_STATUS_CONFIRM.code ? (
                    <IconStatus
                      $active
                      className="fa-solid fa-check"
                    ></IconStatus>
                  ) : (
                    <IconStatus className="fa-solid fa-check"></IconStatus>
                  )}
                </IconContainer>
                <IconContainer className="col d-flex align-items-center justify-content-center">
                  {data.evaluations[dataEval.uuid].status ===
                  EVAL_STATUS_TO_AUTO.code ? (
                    <IconStatus
                      $active
                      className="fa-solid fa-check"
                    ></IconStatus>
                  ) : (
                    <IconStatus className="fa-solid fa-check"></IconStatus>
                  )}
                </IconContainer>
                <IconContainer className="col d-flex align-items-center justify-content-center">
                  {data.evaluations[dataEval.uuid].status ===
                  EVAL_STATUS_AUTO_EVAL.code ? (
                    <IconStatus
                      $active
                      className="fa-solid fa-check"
                    ></IconStatus>
                  ) : (
                    <IconStatus className="fa-solid fa-check"></IconStatus>
                  )}
                </IconContainer>
                <IconContainer className="col d-flex align-items-center justify-content-center">
                  {data.evaluations[dataEval.uuid].status ===
                  EVAL_STATUS_TO_EVAL.code ? (
                    <IconStatus
                      $active
                      className="fa-solid fa-check"
                    ></IconStatus>
                  ) : (
                    <IconStatus className="fa-solid fa-check"></IconStatus>
                  )}
                </IconContainer>
                <IconContainer className="col d-flex align-items-center justify-content-center">
                  {data.evaluations[dataEval.uuid].status ===
                  EVAL_STATUS_EVALUATED.code ? (
                    <IconStatus
                      $active
                      className="fa-solid fa-check"
                    ></IconStatus>
                  ) : (
                    <IconStatus className="fa-solid fa-check"></IconStatus>
                  )}
                </IconContainer>
              </StatusContainer>
            </TdCell>
            <TdCell $odd={index % 2 === 0} className="col-2">
              {renderAction(data.evaluations[dataEval.uuid], dataEval)}
            </TdCell>
          </div>
        );
      });
    } else {
      return <span>{t("noEvaluations")}</span>;
    }
  };

  const goToRegisterEvaluation = (user) => {
    const year = data.year;
    navigate(`/user/evaluation/new/${year}/${user}`);
  };

  const goToEditEvaluation = (user, view = false) => {
    if (view) {
      navigate(`/user/evaluation/view/${data.evaluations[user].uuid}`);
    } else {
      navigate(`/user/evaluation/edit/${data.evaluations[user].uuid}`);
    }
  };

  const renderAction = (evaluation, dataEval) => {
    return (
      <>
        <div className="dropdown text-center">
          <button
            type="button"
            className="btn btn-secondary dropdown-toggle table-action"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {t("actions")}
          </button>
          <ListOptions className="dropdown-menu dropdown-menu-lg-end">
            {evaluation.status === "PENDING" ? (
              <Option>
                <ButtonAction
                  onClick={() => goToRegisterEvaluation(dataEval.uuid)}
                  title={t("forms.evaluation.labels.create")}
                >
                  {/* <Icon className="fa-solid fa-list"></Icon> */}
                  {t("forms.evaluation.labels.create")}
                </ButtonAction>
              </Option>
            ) : (
              ""
            )}
            {evaluation.status === "DRAFT" ? (
              <>
                <Option>
                  <ButtonAction
                    onClick={() => goToEditEvaluation(dataEval.uuid)}
                    title={t("forms.evaluation.labels.edit")}
                  >
                    {/* <Icon className="fa-solid fa-list"></Icon> */}
                    {t("forms.evaluation.labels.edit")}
                  </ButtonAction>
                </Option>
                <Option>
                  <ButtonAction
                    onClick={() =>
                      onConfirm(data.evaluations[dataEval.uuid].uuid)
                    }
                    title={t("forms.evaluation.labels.confirm")}
                  >
                    {/* <Icon className="fa-solid fa-list"></Icon> */}
                    {t("forms.evaluation.labels.confirm")}
                  </ButtonAction>
                </Option>
              </>
            ) : (
              ""
            )}
            {evaluation.status === EVAL_STATUS_CONFIRM.code ? (
              <>
                <Option>
                  <ButtonAction
                    onClick={() => goToEditEvaluation(dataEval.uuid, true)}
                    title={t("forms.evaluation.view")}
                  >
                    {/* <Icon className="fa-solid fa-list"></Icon> */}
                    {t("forms.evaluation.view")}
                  </ButtonAction>
                </Option>
              </>
            ) : (
              ""
            )}
          </ListOptions>
        </div>
      </>
    );
  };

  return (
    <div className="row no-margin">
      <div className="col-12">
        {data ? (
          <>
            <div className="row">
              <ThTitle className="col-3">{t("name")}</ThTitle>
              <ThTitle className="col-4">{t("email")}</ThTitle>
              <ThTitle className="col-3">
                <div className="row">
                  <IconContainer className="col text-center">
                    <IconStatusHead
                      className="fa-solid fa-hourglass-half"
                      title={t("forms.evaluation.status.pending")}
                    ></IconStatusHead>
                  </IconContainer>
                  <IconContainer className="col text-center ">
                    <IconStatusHead
                      className="fa-solid fa-file-pen"
                      title={t("forms.evaluation.status.draft")}
                    ></IconStatusHead>
                  </IconContainer>
                  <IconContainer className="col text-center">
                    <IconStatusHead
                      className="fa-solid fa-clipboard-check"
                      title={t("forms.evaluation.status.confirm")}
                    ></IconStatusHead>
                  </IconContainer>
                  <IconContainer className="col text-center">
                    <IconStatusHead
                      className="fa-solid fa-user-pen"
                      title={t("forms.evaluation.status.toAuto")}
                    ></IconStatusHead>
                  </IconContainer>
                  <IconContainer className="col text-center">
                    <IconStatusHead
                      className="fa-solid fa-user-check"
                      title={t("forms.evaluation.status.autoEval")}
                    ></IconStatusHead>
                  </IconContainer>
                  <IconContainer className="col text-center">
                    <IconStatusHead
                      className="fa-solid fa-user-tie"
                      title={t("forms.evaluation.status.toEval")}
                    ></IconStatusHead>
                  </IconContainer>
                  <IconContainer className="col text-center">
                    <IconStatusHead
                      className="fa-solid fa-flag-checkered"
                      title={t("forms.evaluation.status.evaluated")}
                    ></IconStatusHead>
                  </IconContainer>
                </div>
              </ThTitle>
              <ThTitle className="col-2"></ThTitle>
            </div>
            {renderEvaluated()}
          </>
        ) : (
          t("noEvaluation")
        )}
      </div>
    </div>
  );
};

EvaluationListView.propTypes = {
  data: PropTypes.object,
};

export default EvaluationListView;
