import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ConfigForm from "./config.view";
// import * as UserServices from "../../../../services/UserServices";
import * as ConfigServices from "../../../services/ConfigServices";
import showDialog from "../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const ConfigFormContainer = () => {
  const { t } = useTranslation();
  const [initValues, setInitValues] = useState({});
  const [data, setData] = useState({});

  const loadInitialValues = (dataResult) => {
    const val = {
      dateInitObj: dataResult.dateInitObj,
      dateEndObj: dataResult.dateEndObj,
      dateInitEval: dataResult.dateInitEval,
      dateEndEval: dataResult.dateEndEval,
    };
    setInitValues(val);
    setData(dataResult);
  };

  useEffect(() => {
    ConfigServices.getConfiguration().then((result) => {
      if (result) {
        loadInitialValues(result);
      }
    });
  }, []);

  const handleSubmit = async (values) => {
    let title = t("forms.config.message.register.title");
    let text = "";
    let icon = "";
    try {
      let result = await ConfigServices.updateConfig(values);
      if (result) {
        text = t("forms.config.message.register.success");
        icon = "success";
      } else {
        text = t("forms.config.message.register.error");
        icon = "error";
      }
      showDialog(title, text, icon, false, "OK", false);
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const handlerSendNotification = async (type) => {
    try {
      const okCopyAction = () => {
        sendNotifications(type);
      };

      const cancelAction = () => {};

      showDialog(
        t(`forms.config.dialog.notification.title`),
        t(`forms.config.dialog.notification.text_${type}`),
        `question`,
        true,
        t(`forms.config.dialog.notification.buttonOk`),
        false,
        okCopyAction,
        t(`forms.config.dialog.notification.buttonNo`),
        cancelAction
      );
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const sendNotifications = async (type) => {
    let title = "";
    let text = "";
    let icon = "";

    try {
      const result = await ConfigServices.reminder(type);
      if (result) {
        title = t(`forms.config.message.notification.title`);
        text = t(`forms.config.message.notification.success`);
        icon = "success";
      }
      showDialog(title, text, icon, false, "OK", false);
    } catch (error) {}
  };

  const handlerCloseStep = async (type) => {
    try {
      const okCopyAction = () => {
        closeStep(type);
      };

      const cancelAction = () => {};

      showDialog(
        t(`forms.config.dialog.closeStep.title_${type}`),
        t(`forms.config.dialog.closeStep.text_${type}`),
        `warning`,
        true,
        t(`forms.config.dialog.closeStep.buttonOk`),
        false,
        okCopyAction,
        t(`forms.config.dialog.closeStep.buttonNo`),
        cancelAction
      );
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const closeStep = async (type) => {
    let title = "";
    let text = "";
    let icon = "";

    try {
      const result = await ConfigServices.closeStep(type);
      if (result) {
        loadInitialValues(result);
        title = t(`forms.config.message.closeStep.title`);
        text = t(`forms.config.message.closeStep.success`);
        icon = "success";
      }
      showDialog(title, text, icon, false, "OK", false);
    } catch (error) {}
  };

  return (
    <ConfigForm
      initialValues={initValues}
      onSubmit={handleSubmit}
      onSendNotification={handlerSendNotification}
      onCloseStep={handlerCloseStep}
      data={data}
    />
  );
};

ConfigFormContainer.propTypes = {};

export default ConfigFormContainer;
