import React from "react";
import { useTranslation } from "react-i18next";
// import {
//   getUserRankSelector,
//   getNicknameSelector,
// } from "../../../store/selectors/authSelector";
import { checkBootstrapSizeBreak } from "../../../utils/device/checkDevice";
import { Container, TitleContainer, LastProcessContainer } from "./home.style";

const HomeView = (props) => {
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  return (
    <>
      <div className="row mt-4">
        <div className="col-12 col-md-12 col-lg-3">
          <div className="row">
            <div className="col-12"></div>
          </div>
          {/* <div className="row">
                    <div className="col-12 mt-3">
                      <TitleContainer>
                        {t("forms.dashboard.ranks")}
                      </TitleContainer>
                      <MedalContainer></MedalContainer>
                    </div>
                  </div> */}
          {/* <div className="row">
                    <div className="col-12 mt-3">
                      <TitleContainer>
                        {t("forms.dashboard.wallets")}
                      </TitleContainer>
                      <MyWalletContainer>
                        
                      </MyWalletContainer>
                    </div>
                  </div> */}
        </div>
        <div className="col-12 col-md-12 col-lg-6 mt-4 mt-md-3 mt-lg-0 mb-2">
          <div className="row">
            <div className="col-12">
              {/* <TitleContainer>{t("forms.process.lastProcess")}</TitleContainer>
              <LastProcessContainer $devicesize={deviceSize}>
                <LastProcess></LastProcess>
              </LastProcessContainer> */}
            </div>
          </div>
          {/* <div className="row">
                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                      <div className="row">
                        <div className="col-12">
                          <TitleContainer>
                            {t("forms.dashboard.qualify")}
                          </TitleContainer>
                          <MyQualifyContainer>
                          </MyQualifyContainer>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3">
                          <TitleContainer>
                            {t("forms.dashboard.commission")}
                          </TitleContainer>
                          <MyWalletContainer>
                          </MyWalletContainer>
                        </div>
                      </div>
                    </div> 
                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                      <TitleContainer>
                        {t("forms.dashboard.volume")}
                      </TitleContainer>
                      <UserContainer>
                      </UserContainer>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-3">
                      <TitleContainer>
                        {t("forms.dashboard.promotor")}
                      </TitleContainer>
                      <UserContainer>
                      </UserContainer>
                    </div>
                  </div>*/}
        </div>
      </div>
    </>
  );
};
export default HomeView;
