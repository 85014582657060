import styled from "styled-components";

export const ThTitle = styled.div`
  font-size: 1rem;
  background-color: #203b34;
  color: #fff;
  border-right: 1px solid #fff;
  padding: 8px 10px;
`;

export const TdCell = styled.div`
  font-size: 0.875rem;
  background-color: ${({ $odd }) => ($odd ? "#fff" : "#fafafa")};
  color: #000;
  border-left: ${({ $first }) =>
    $first ? "1px solid #dcdcdc" : "0px solid #dcdcdc"};
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  padding: 5px 10px;
  height: 45px;
`;

export const StatusContainer = styled.div`
  height: 40px;
`;

export const ButtonAction = styled.button`
  margin-right: 2px;
  color: #f9ae17;
  font-size: 1rem;
  border: 0px;
  background: transparent;
  width: max-content;

  &:hover {
    color: #ff9800;
    font-size: 1rem;
  }
`;

export const IconStatus = styled.i`
  color: ${({ $active }) => ($active ? "green" : "#e9e9e9")};
  font-size: 0.85rem;
`;

export const Option = styled.li`
  padding: 10px;
`;

export const ListOptions = styled.ul`
  background: #203b34;
`;

export const ButtonClose = styled.button`
  font-size: 1rem;
  border: 0px;
  background: transparent;
`;

export const IconStatusHead = styled.i`
  color: #fff;
`;

export const IconContainer = styled.div`
  padding-right: 5px;
  padding-left: 5px;
  font-size: 0.85rem;
`;
