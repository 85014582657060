import LoginForm from "./login.view";
import { AuthContainer } from "../security/auth/auth.container";
import { useNavigate } from "react-router";
import PATHS from "../../constants/paths";

const LoginContainer = () => {
  // const navigate = useNavigate();
  // const auth = AuthContainer.useContainer();

  const handleSubmit = async (values) => {
    window.location.href = `${process.env.REACT_APP_LOGIN_URI}`;
  };

  // const handleLoginAzure = async (values) => {
  //   // await UserServices.profile1();
  //   openCenteredPopup(
  //     `http://localhost:4000/api/v1/user/auth/azure`,
  //     `login with azureAd`,
  //     200,
  //     200
  //   );
  //   // await UserServices.loginAzure();
  //   // const result = await auth.login(values.user, values.password);
  //   // if (result && result === "OK") {
  //   //   navigate(PATHS.HOME);
  //   // }
  // };

  return <LoginForm gotoLogin={handleSubmit}></LoginForm>;
};

export default LoginContainer;

// export const openCenteredPopup = (url, title, w, h) => {
//   // Fixes dual-screen position                             Most browsers      Firefox
//   const dualScreenLeft =
//     window.screenLeft !== undefined ? window.screenLeft : window.screenX;
//   const dualScreenTop =
//     window.screenTop !== undefined ? window.screenTop : window.screenY;

//   const width = window.innerWidth
//     ? window.innerWidth
//     : document.documentElement.clientWidth
//     ? document.documentElement.clientWidth
//     : window.screen.width;
//   const height = window.innerHeight
//     ? window.innerHeight
//     : document.documentElement.clientHeight
//     ? document.documentElement.clientHeight
//     : window.screen.height;

//   const systemZoom = width / window.screen.availWidth;
//   const left = (width - w) / 2 / systemZoom + dualScreenLeft;
//   const top = (height - h) / 2 / systemZoom + dualScreenTop;
//   const newWindow = window.open(
//     url,
//     title,
//     `
//       scrollbars=no,
//       toolbar=no,
//       location=no,
//       directories=no,
//       status=no,
//       menubar=no,
//       resizable=no,
//       copyhistory=no,
//       width=${w / systemZoom},
//       height=${h / systemZoom},
//       top=${top},
//       left=${left}
//       `
//   );

//   newWindow?.focus();
// };
