import * as Yup from "yup";

export const dataModel = {
  dateInitObj: "",
  dateEndObj: "",
  // dateInitEval: "",
  // dateEndEval: "",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    dateInitObj: Yup.date().required("forms.validations.required"),
    dateEndObj: Yup.date()
      .required("forms.validations.required")
      .min(Yup.ref("dateInitObj"), "forms.validations.date.dateInitObj"),
    // dateInitEval: Yup.date()
    //   .required("forms.validations.required")
    //   .min(Yup.ref("dateEndObj"), "forms.validations.date.dateEndObj"),
    // dateEndEval: Yup.date()
    //   .required("forms.validations.required")
    //   .min(Yup.ref("dateInitEval"), "forms.validations.date.dateInitEval"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
