import { store } from "../store/index";
import {
  getConfig,
  putConfig,
  finishStep,
  sendReminder,
} from "../store/actions/ConfigActions";

export const getConfiguration = async () => {
  const result = await store.dispatch(getConfig());
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const updateConfig = async (values) => {
  const obj = { ...values };
  const result = await store.dispatch(putConfig(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const closeStep = async (type) => {
  const obj = { type };
  const result = await store.dispatch(finishStep(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const reminder = async (type) => {
  const obj = { type };
  const result = await store.dispatch(sendReminder(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};
