import React from "react";
import EvalConsult from "../../../components/user/evaluation/evaluationConsultList";
import Layout from "../../../components/layouts/verticalLayout";

const EvalConsultPage = (props) => {
  return <Layout body={<EvalConsult />} />;
};

EvalConsultPage.propTypes = {};

export default EvalConsultPage;
