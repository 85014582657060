import { createAction } from "redux-actions";
import { apiGet, apiPost } from "../../api/index";
import {
  urlConfigSecured,
  urlConfigFinishSecured,
  urlConfigReminderSecured,
} from "../../api/urls";

/**
 * Update Config.
 */
export const putConfig = createAction("UPDATE_CONFIG", (obj) =>
  apiPost(urlConfigSecured, obj, undefined, true)()
);

/**
 * Get Config.
 */
export const getConfig = createAction("GET_EVALUATION", () =>
  apiGet(urlConfigSecured, undefined, true)()
);

/**
 * Finish step.
 */
export const finishStep = createAction("CLOSE_STEP", (obj) =>
  apiPost(urlConfigFinishSecured, obj, undefined, true)()
);

/**
 * Send reminder.
 */
export const sendReminder = createAction("SEND_REMINDER", (obj) =>
  apiPost(urlConfigReminderSecured, obj, undefined, true)()
);
