import React from "react";
import EvalList from "../../../components/user/evaluation/evaluationFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const EvalListPage = (props) => {
  return <Layout body={<EvalList />} />;
};

EvalListPage.propTypes = {};

export default EvalListPage;
