import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { ToastContainer } from "react-toastify";
// import { Flip } from "react-toastify";
import { getDataTokenSelector } from "../../../../store/selectors/authSelector";
// import SelectTimeZone from "./components/selectTimeZone";
import Languages from "./components/languages";
import UserMenuHeader from "./components/menu";
import LoadingModal from "../../../utils/modalLoading";
import {
  HeaderContainer,
  Logo,
  LogoMobile,
  SidebarButtonContainer,
  SidebarButton,
} from "./styled";
// import ModalInfo from "../../../utils/modalInfo/ModalInfo";
import ModalForm from "../../../utils/modalForm";
import IconNotification from "./components/iconNotification";
// import { Can } from "../../../permissions/Can";
// import { USER_PERMISSIONS } from "../../../permissions/rolesPermission";

const Header = (props) => {
  const { t } = useTranslation();
  const token = useSelector(getDataTokenSelector);

  return (
    <>
      <LoadingModal />
      <ModalForm />
      {/*  <ModalInfo></ModalInfo> */}
      <HeaderContainer className={`row justify-content-between`}>
        {/* {type !== "login" && (
            <>
              <div className="col-2 col-md-6 col-lg-4 align-self-center ">
                <Logo
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/text_white.png`}
                  alt="logo"
                  className="logo d-none d-lg-block"
                />
                <LogoMobile
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/simpleLogo.png`}
                  alt="logo"
                  className="logo d-block d-md-none"
                />
              </div>
            </>
          )} */}

        <div className={`col-12`}>
          <div className="row">
            <div className="col-12 d-flex justify-content-end align-items-center pt-2">
              {/* {token && (
                <>
                  <IconNotification key="personal_notify" />
                </>
              )} */}
              <Languages />
              {token ? (
                <>
                  <SidebarButtonContainer className="d-block d-lg-none">
                    <SidebarButton
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                    >
                      <i className="fas fa-bars" />
                    </SidebarButton>
                  </SidebarButtonContainer>
                  {/* <UserMenuHeader /> */}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </HeaderContainer>
      {/* <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          transition={Flip}
        /> */}
    </>
  );
};

Header.propTypes = {
  withSidebar: PropTypes.bool,
  toggleFunc: PropTypes.func,
};

export default Header;
