import styled from "styled-components";

export const TotalWeight = styled.div`
  color: ${({ $error }) => ($error ? "red" : "#000")};
`;

export const Subtitle = styled.div`
  font-size: 1.5rem;
  text-decoration: underline;
`;

export const ContainerTooltip = styled.div`
  max-width: 300px;
`;
