import React from "react";
import EvaluationForm from "../../../components/user/evaluation/evaluationForm";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const NewEvaluationPage = () => {
  let { year, userUuid, uuid } = useParams();
  return (
    <Layout
      body={
        <>
          <EvaluationForm userUuid={userUuid} year={year} uuid={uuid} />
        </>
      }
    />
  );
};

NewEvaluationPage.propTypes = {};

export default NewEvaluationPage;
