import React from "react";
import EvaluationForm from "../../../components/user/evaluation/evaluationForm";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const DetailEvaluationPage = () => {
  let { uuid } = useParams();
  return (
    <Layout
      body={
        <>
          <EvaluationForm onlyView uuid={uuid} />
        </>
      }
    />
  );
};

DetailEvaluationPage.propTypes = {};

export default DetailEvaluationPage;
