import { useEffect } from "react";
import Home from "./home.view";
// import { useNavigate } from "react-router";
// import PATHS from "../../constants/paths";
const HomeContainer = () => {
  // const navigate = useNavigate();

  return <Home />;
};

export default HomeContainer;
