import React, { useState } from "react";
import List from "../evaluationList";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import Select from "react-select";

const EvalFilterListContainer = (props) => {
  const { t } = useTranslation();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.evaluation.title") },
  ];
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let index = 0; index < 5; index++) {
    years.push({
      value: currentYear - index,
      label: currentYear - index,
    });
  }

  const [year, setYear] = useState({ value: currentYear, label: currentYear });

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.evaluation.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center mb-3">
        <div className="col-12 col-md-11">
          <div className="row">
            <div className="col-3">
              <Select
                id="year"
                name="year"
                value={year}
                onChange={(value) => {
                  setYear(value);
                }}
                options={years}
                classNamePrefix="dark"
                placeholder=""
                // className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <List className="marg-top-1rem" year={year.value || currentYear} />
        </div>
      </div>
    </>
  );
};

EvalFilterListContainer.propTypes = {};

export default EvalFilterListContainer;
