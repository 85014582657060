import React, { useState, useEffect } from "react";
import * as EvaluationService from "../../../../services/EvaluationServices";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import EvaluationList from "./evaluationList.view";
import showDialog from "../../../utils/ShowDialog/ShowDialog";

const OrderListContainer = ({ year }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  const loadData = (dataYear) => {
    EvaluationService.getEvaluation(dataYear).then((result) => {
      setData(result);
    });
  };

  useEffect(() => {
    loadData(year);
  }, [year]);

  const showDialogOnConfirm = async (uuid) => {
    try {
      const okAction = async () => {
        try {
          const result = await EvaluationService.confirmEvaluation(uuid);
          if (result) {
            loadData(year);
            showDialog(
              t("forms.evaluation.message.confirm.title"),
              t("forms.evaluation.message.confirm.text"),
              "success",
              false,
              "OK"
            );
          }
        } catch (error) {
          // console.log(error);
        }
      };

      const cancelAction = () => {};

      showDialog(
        t("forms.evaluation.dialog.confirm.title"),
        t("forms.evaluation.dialog.confirm.text"),
        "question",
        true,
        t("forms.evaluation.dialog.confirm.buttonOk"),
        false,
        okAction,
        t("forms.evaluation.dialog.confirm.buttonNo"),
        cancelAction
      );
    } catch (error) {}
  };

  return (
    <>
      <EvaluationList
        data={data}
        onConfirm={showDialogOnConfirm}
      ></EvaluationList>
    </>
  );
};

export default OrderListContainer;
