import * as Yup from "yup";

export const dataModel = {
  criterial01: "",
  criterial02: "",
  criterial03: "",
  criterial04: "",
  criterial05: "",
  weightCriterial01: 0,
  weightCriterial02: 0,
  weightCriterial03: 0,
  weightCriterial04: 0,
  weightCriterial05: 0,
};

export const validationDataModel = () => {
  return Yup.object().shape({
    criterial01: Yup.string()
      .optional("forms.validations.required")
      .max(180, { message: "forms.validations.max", args: { max: 180 } }),
    criterial02: Yup.string()
      .optional("forms.validations.required")
      .max(180, { message: "forms.validations.max", args: { max: 180 } }),
    criterial03: Yup.string()
      .optional("forms.validations.required")
      .max(180, { message: "forms.validations.max", args: { max: 180 } }),
    criterial04: Yup.string()
      .optional("forms.validations.required")
      .max(180, { message: "forms.validations.max", args: { max: 180 } }),
    criterial05: Yup.string()
      .optional("forms.validations.required")
      .max(180, { message: "forms.validations.max", args: { max: 180 } }),
    weightCriterial01: Yup.number().optional("forms.validations.required"),
    weightCriterial02: Yup.number().optional("forms.validations.required"),
    weightCriterial03: Yup.number().optional("forms.validations.required"),
    weightCriterial04: Yup.number().optional("forms.validations.required"),
    weightCriterial05: Yup.number().optional("forms.validations.required"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
