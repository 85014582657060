import React from "react";
import ConfigForm from "../../components/user/config";
import Layout from "../../components/layouts/verticalLayout";

const ConfigPage = () => {
  return (
    <Layout
      body={
        <>
          <ConfigForm />
        </>
      }
    />
  );
};

ConfigPage.propTypes = {};

export default ConfigPage;
