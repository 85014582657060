import { store } from "../store/index";
import {
  getEval,
  generateEvaluation,
  getSimpleEvaluation,
  putEvaluation,
  evaluationConfirm,
  getDataToEvaluation,
  consultEval,
  filterEvaluations,
  listEvaluationsToExport,
} from "../store/actions/EvaluationActions";

export const getUserEvaluation = async (uuid) => {
  const result = await store.dispatch(getSimpleEvaluation([uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const getEvaluation = async (year) => {
  const result = await store.dispatch(getEval(year));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const createEvaluation = async (values, year, userUuid) => {
  const obj = { ...values };
  obj.evaluatedUuid = userUuid;
  obj.year = Number(year);
  const result = await store.dispatch(generateEvaluation(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const updateEvaluation = async (values, evaluation) => {
  const obj = { ...values };
  const param = [evaluation.uuid];
  const result = await store.dispatch(putEvaluation(obj, param));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const confirmEvaluation = async (uuid) => {
  const result = await store.dispatch(evaluationConfirm([uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const dataForEval = async (userUuid) => {
  const result = await store.dispatch(getDataToEvaluation([userUuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const consultEvaluations = async (filter) => {
  const result = await store.dispatch(consultEval(filter));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * List evaluations
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterEvaluations(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List export
 */
export const listToExport = async (filter) => {
  const result = await store.dispatch(listEvaluationsToExport(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
