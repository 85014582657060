import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NewEvaluationForm from "./evaluationForm.view";
import * as UserServices from "../../../../services/UserServices";
import * as EvaluationServices from "../../../../services/EvaluationServices";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const NewProcessFormContainer = ({ year, userUuid, uuid, onlyView }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [initValues, setInitValues] = useState({});
  const [dataForm, setDataForm] = useState(null);
  const [evaluation, setEvaluation] = useState(null);
  const [edit, setEdit] = useState(false);

  const loadInitialValues = (data) => {
    const val = {
      criterial01: data.criterial01,
      criterial02: data.criterial02,
      criterial03: data.criterial03,
      criterial04: data.criterial04,
      criterial05: data.criterial05,
      weightCriterial01: data.weightCriterial01,
      weightCriterial02: data.weightCriterial02,
      weightCriterial03: data.weightCriterial03,
      weightCriterial04: data.weightCriterial04,
      weightCriterial05: data.weightCriterial05,
    };
    setInitValues(val);
  };

  useEffect(() => {
    if (uuid) {
      EvaluationServices.getUserEvaluation(uuid).then((result) => {
        if (result?.professionalSkill && result.professionalSkill.length > 0) {
          let count = 1;
          for (const skill of result.professionalSkill) {
            const languages = Object.keys(skill.translations);
            for (const lng of languages) {
              i18n.addResourceBundle(
                lng,
                `skill`,
                { [`skill_${count}`]: skill.translations[lng] },
                true,
                true
              );
            }
            count++;
          }
        }
        loadInitialValues(result);
        setEvaluation(result);
        setEdit(true);
      });
    } else {
      EvaluationServices.dataForEval(userUuid).then((result) => {
        if (result?.skills && result.skills.length > 0) {
          let count = 1;
          for (const skill of result.skills) {
            const languages = Object.keys(skill.translations);
            for (const lng of languages) {
              i18n.addResourceBundle(
                lng,
                `skill`,
                { [`skill_${count}`]: skill.translations[lng] },
                true,
                true
              );
            }
            count++;
          }
        }
        setDataForm(result);
      });
    }
  }, [userUuid, uuid]);

  const handleSubmit = async (values) => {
    let title = "";
    let text = "";
    let icon = "";
    let okAction = () => {
      navigate(PATHS.EVALUATION);
    };
    try {
      let result;
      const totals =
        Number(values.weightCriterial01) +
        Number(values.weightCriterial02) +
        Number(values.weightCriterial03) +
        Number(values.weightCriterial04) +
        Number(values.weightCriterial05);
      if (totals <= 100) {
        if (edit && evaluation) {
          result = await EvaluationServices.updateEvaluation(
            values,
            evaluation
          );
        } else {
          result = await EvaluationServices.createEvaluation(
            values,
            year,
            userUuid
          );
        }
        if (result) {
          title = t("forms.evaluation.message.register.title");
          text = t("forms.evaluation.message.register.success");
          icon = "success";
        }
      } else {
        title = t("forms.evaluation.message.register.title");
        text = t("forms.evaluation.message.register.errorWeight");
        icon = "error";
        okAction = () => {};
      }
      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const cancel = async (values) => {
    navigate(PATHS.EVALUATION);
  };

  return (
    <NewEvaluationForm
      initialValues={initValues}
      dataForm={dataForm}
      onSubmit={handleSubmit}
      cancelAction={cancel}
      edit={edit}
      evaluation={evaluation}
      onlyView={onlyView}
    />
  );
};

NewProcessFormContainer.propTypes = {};

export default NewProcessFormContainer;
