import React, { useState, useEffect } from "react";
import * as EvaluationService from "../../../../services/EvaluationServices";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PaginateList from "../../../utils/PaginateList";
import { ButtonAction, ButtonClose, Icon } from "./evaluationListAll.styled";
import { show, hide } from "redux-modal";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";
import { EVAL_STATUS } from "../../../../constants/index";
import Detail from "../evaluationConsult/evaluationDetailview";

const EvaluationListAllContainer = ({ filters, sortBy }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      header: t("forms.evaluation.labels.year"),
      accessorKey: "year",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.evaluation.labels.evaluated"),
      accessorKey: "name",
      cell: (info) => {
        if (info.row.original.evaluated) {
          return `${info.row.original.evaluated.name} ${info.row.original.evaluated.surname}`;
        }
        return info.getValue();
      },
    },
    {
      header: t("forms.evaluation.labels.email"),
      accessorKey: "email",
      cell: (info) => {
        if (info.row.original.evaluated) {
          return `${info.row.original.evaluated.email}`;
        }
        return info.getValue();
      },
    },
    {
      header: t("forms.evaluation.labels.evaluator"),
      accessorKey: "evaluator",
      cell: (info) => {
        if (info.row.original.evaluator) {
          return `${info.row.original.evaluator.name} ${info.row.original.evaluator.surname}`;
        }
        return info.getValue();
      },
    },
    {
      header: t("forms.evaluation.labels.status"),
      accessorKey: "itemType",
      cell: (info) => {
        if (info.row.original.status) {
          const type = EVAL_STATUS.find(
            (st) => st.code === info.row.original.status
          );
          return type ? t(type.label) : info.getValue();
        }
        return "-";
      },
    },
    {
      header: t("actions"),
      accessorKey: "uuid",
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const showEvaluation = (data) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Detail
                  data={data}
                  cancelAction={() => handlerClosePopup("ModalForm")}
                ></Detail>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const renderAction = (data) => {
    return (
      <>
        <div className="text-center">
          <ButtonAction
            onClick={() => showEvaluation(data)}
            title={t("forms.consult.detail")}
          >
            <Icon className="fa-solid fa-file-lines"></Icon>
            {t("forms.consult.detail")}
          </ButtonAction>
        </div>
      </>
    );
  };

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-date";
    }
    const result = await EvaluationService.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  if (deviceSize < SIZES.md) {
    hideColumn.itemCode = false;
    resize = false;
  } else if (deviceSize === SIZES.md) {
    hideColumn.itemCode = false;
  }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default EvaluationListAllContainer;
