import React from "react";
import { useTranslation } from "react-i18next";
import { EVAL_STATUS } from "../../../../constants/index";
import { LabelText, ValueText, Subtitle } from "./evaluationConsult.styled";

const DetailEvaluationView = (props) => {
  const { t } = useTranslation();
  const { data, cancelAction } = props;
  const evaluation = data && data.evaluation ? data.evaluation[0] : data;
  const status = EVAL_STATUS.find((f) => f.code === evaluation.status);
  const renderSkills = () => {
    let listSkills = evaluation.professionalSkill;
    return listSkills.map((skill, index) => {
      return (
        <div className="row" key={`skill_${index}`}>
          <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
            {`${index + 1}.`}
          </div>
          <div className="col-1 mt-2">
            <strong>{`${skill.weight}%`}</strong>
          </div>
          <div className="col-8 mt-2">
            <strong>{skill.name}</strong>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <h3 className="title">{t("forms.evaluation.detail")}</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <div className="row justify-content-center">
            <div className="col-12 mt-3 d-flex">
              <LabelText>{t("forms.evaluation.labels.evaluated")}:</LabelText>
              <ValueText>{`${data.name || data.evaluated.name} ${
                data.surname || data.evaluated.surname
              }`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex">
              <LabelText>{t("forms.evaluation.labels.year")}:</LabelText>
              <ValueText>{`${evaluation.year}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex">
              <LabelText>{t("forms.evaluation.labels.status")}:</LabelText>
              <ValueText>{`${t(status.label)}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex">
              <LabelText>{t("forms.evaluation.labels.evaluator")}:</LabelText>
              <ValueText>{`${data.evaluator.name} ${data.evaluator.surname}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <Subtitle className="col-12 mt-4 mb-2">
              {t("forms.evaluation.labels.criterialObj")}
            </Subtitle>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end"></div>
                <div className="col-1 mt-2 text-start">
                  {t("forms.evaluation.labels.weight")}
                </div>
                <div className="col-8 mt-2 text-start">
                  {t("forms.evaluation.labels.criterial")}
                </div>
              </div>
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  1.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${evaluation.weightCriterial01}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${evaluation.criterial01}`}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  2.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${evaluation.weightCriterial02}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${evaluation.criterial02}`}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  3.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${evaluation.weightCriterial03}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${evaluation.criterial03}`}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  4.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${evaluation.weightCriterial04}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${evaluation.criterial04}`}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  5.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${evaluation.weightCriterial05}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${evaluation.criterial05}`}</strong>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <Subtitle className="col-12 mt-3 mb-2">
              {t("forms.evaluation.labels.criterialStd")}
            </Subtitle>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end"></div>
                <div className="col-1 mt-2 text-start">
                  {t("forms.evaluation.labels.weight")}
                </div>
                <div className="col-8 mt-2 text-start">
                  {t("forms.evaluation.labels.skill")}
                </div>
              </div>
            </div>
          </div>
          {renderSkills()}
          <div className="row mt-5 mb-5">
            <div className="col-12 text-center">
              <button
                type="button"
                onClick={() => cancelAction()}
                className="btn btn-lg btn-secondary me-2"
              >
                {t("forms.evaluation.buttons.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DetailEvaluationView.propTypes = {};

export default DetailEvaluationView;
