const urlBase = `${process.env.REACT_APP_API_PROTOCOL}://${
  process.env.REACT_APP_API_HOST
}${
  process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ""
}/api/`;

/** AUTH **/
export const urlGetLoginItemTemp = `${urlBase}v1/items/login`;

export const urlRefreshToken = `${urlBase}v1/user/refresh-token`;
export const urlLogout = `${urlBase}v1/user/logout`;
export const urlGetProfile = `${urlBase}v1/user-s/profile`;

export const urlPreRegisterEmployee = `${urlBase}v1/employeeRequest-s/preRegister`;
export const urlEvaluationSecured = `${urlBase}v1/evaluation-s`;
export const urlDataToEvaluationSecured = `${urlBase}v1/evaluation-s/loadForm`;
export const urlEvaluationConfirmSecured = `${urlBase}v1/evaluation-s/confirm`;
export const urlToEvaluationSecured = `${urlBase}v1/toEval-s`;
export const urlToEvaluationConsultSecured = `${urlBase}v1/toEval-s/consult`;
export const urlDataUser = `${urlBase}v1/dataUser-s`;
export const urlConfigSecured = `${urlBase}v1/config-s`;
export const urlConfigFinishSecured = `${urlBase}v1/config-s/finishStep`;
export const urlConfigReminderSecured = `${urlBase}v1/config-s/reminder`;
