import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #203b34;
  padding: 20px 20px;
  border-radius: 10px;
`;

export const Subtitle = styled.div`
  font-size: 1.2rem;
  text-decoration: underline;
  margin-bottom: 10px;
`;

export const FinishStep = styled.div`
  font-size: 1rem;
  color: red;
  margin-bottom: 10px;
  font-weight: bold;
`;
