import React, { useState } from "react";
import List from "../evaluationConsult";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
// import Select from "react-select";
import Filter from "../consultFilter";
const EvalFilterListContainer = (props) => {
  const { t } = useTranslation();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.consult.title") },
  ];
  const [filters, setFilters] = useState(undefined);

  // const currentYear = new Date().getFullYear();
  // const years = [];
  // for (let index = 0; index < 5; index++) {
  //   years.push({
  //     value: currentYear - index,
  //     label: currentYear - index,
  //   });
  // }

  // const [year, setYear] = useState({ value: currentYear, label: currentYear });

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.consult.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div>
      {/* <div className="row justify-content-center mb-3">
        <div className="col-12 col-md-11">
          <div className="row">
            <div className="col-3">
              <Select
                id="year"
                name="year"
                value={year}
                onChange={(value) => {
                  setYear(value);
                }}
                options={years}
                classNamePrefix="dark"
                placeholder=""
                // className="form-control"
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <List className="marg-top-1rem" filters={filters} />
        </div>
      </div>
    </>
  );
};

EvalFilterListContainer.propTypes = {};

export default EvalFilterListContainer;
