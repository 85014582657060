import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  /* background: ${() =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/dashboard_back.jpg')`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */
`;

export const TitleContainer = styled.div`
  color: #203b34;
  background-image: linear-gradient(
    90deg,
    transparent 0%,
    rgba(249, 174, 23, 0.8) 0%,
    rgba(249, 174, 23, 0.4) 50%,
    transparent 80%
  );
  /* 198,169,105
  160,197,149 */
  height: 30px;
  text-transform: uppercase;
  font-size: 0.875rem;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  padding-left: 15px;
  border-radius: 15px;
`;

export const LastProcessContainer = styled.div`
  border: 1px solid #fff;
  background-color: #2e4e463e;
  padding: ${({ $devicesize }) => ($devicesize < 3 ? "20px 0px" : "20px")};
  border-radius: 15px;
  min-height: 100px;
`;

// export const HeaderContainer = styled.div`
//   background-color: #292929;
//   min-height: 55px;
// `;

// export const ImageEscudo = styled.img`
//   height: 35px;
//   margin-right: 10px;
// `;

// export const Name = styled.span`
//   color: #fff;
//   text-transform: uppercase;
//   font-size: 1.4rem;
//   font-weight: 400;
// `;

// export const LabelHeader = styled.span`
//   color: #fff;
//   text-transform: uppercase;
//   font-size: 0.75rem;
//   font-weight: 400;
//   margin-right: 5px;
// `;

// export const ValueHeader = styled.span`
//   color: ${({ theme }) => theme.colors.primary};
//   font-size: 0.75rem;
//   font-weight: 400;
// `;

// export const DefaultContainer = styled.div`
//   border: 1px solid #fff;
//   background-color: #0000005c;
//   padding: 20px;
//   border-radius: 15px;
//   min-height: 290px;
// `;

// export const UserContainer = styled.div`
//   border: 1px solid #fff;
//   background-color: #0000005c;
//   padding: 20px;
//   border-radius: 15px;
//   min-height: 180px;
// `;

// export const MedalContainer = styled.div`
//   border: 1px solid #fff;
//   background-color: #0000005c;
//   padding: 20px;
//   border-radius: 15px;
//   min-height: 180px;
// `;

// export const MyWalletContainer = styled.div`
//   border: 1px solid #fff;
//   background-color: #0000005c;
//   padding: 20px;
//   border-radius: 15px;
//   min-height: 120px;
// `;

// export const MyQualifyContainer = styled.div`
//   border: 1px solid #fff;
//   background-color: #0000005c;
//   padding: 20px;
//   border-radius: 15px;
//   min-height: 230px;
// `;

// export const StatusContainer = styled.div`
//   border: 1px solid #fff;
//   background-color: #0000005c;
//   padding: 5px 10px 10px 15px;
//   border-radius: 15px;
//   min-height: 50px;
// `;
//*************************************************** */
// export const SubContainer = styled(DashBoardItem)`
//   border: 0px;
// `;

// export const ButtonGo = styled.button`
//   border: 0px;
//   background: transparent;
//   display: flex;
//   align-items: center;
// `;

// export const InfoIcon = styled.i`
//   color: ${({ passed }) => (passed === true ? "#e03a85" : "#e7e7e7")};
//   margin-right: 20px;
//   margin-left: 10px;
//   font-size: 2rem;

//   &:hover {
//     color: #e03a85;
//   }
// `;

// export const ConfirmIcon = styled.i`
//   color: green;
//   /* margin-right: 5px; */
//   /* margin-left: ${({ type }) =>
//     type ? (type === "wallet" ? "28px" : "75px") : "16px"}; */
//   font-size: 1.2rem;
// `;

// export const PendingIcon = styled.i`
//   color: #e7e7e7;
//   /* margin-right: 5px; */
//   /* margin-left: ${({ type }) =>
//     type ? (type === "wallet" ? "28px" : "66px") : "16px"}; */
//   font-size: 1.2rem;
// `;

// export const Container01 = styled.div`
//   border: 1px solid ${({ theme }) => theme.colors.primary};
//   background-color: ${({ theme }) => theme.colors.primary};
//   height: ${({ breakDevice }) => (breakDevice <= 3 ? "initial" : "220px")};
//   -webkit-box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%);
//   -moz-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
//   box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%);
//   padding: 20px;
//   border-radius: 5px;
// `;

// export const Container02 = styled.div`
//   border: 1px solid ${({ theme }) => theme.colors.secondary};
//   background-color: ${({ theme }) => theme.colors.secondary};
//   height: ${({ breakDevice }) => (breakDevice <= 4 ? "initial" : "220px")};
//   -webkit-box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%);
//   -moz-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
//   box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%);
//   padding: 20px;
//   border-radius: 5px;
// `;

// export const Container03 = styled.div`
//   border: 1px solid ${({ theme }) => theme.colors.terciary};
//   background-color: ${({ theme }) => theme.colors.terciary};
//   height: ${({ breakDevice }) => (breakDevice <= 4 ? "initial" : "220px")};
//   -webkit-box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%);
//   -moz-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
//   box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%);
//   padding: 20px;
//   border-radius: 5px;
// `;

// export const SponsorLabel = styled.div`
//   color: #fff;
//   font-size: 1rem;
//   font-family: "Gotham";
//   text-transform: uppercase;
// `;

// export const MyUpline = styled.div`
//   color: ${({ theme }) => theme.colors.terciary};
//   font-size: 0.875rem;
//   font-family: "Gotham";
//   letter-spacing: 2px;
//   text-transform: uppercase;
//   font-weight: bold;
// `;

// export const SponsorURL = styled.div`
//   color: #fff;
//   font-size: 0.875rem;
// `;

// export const Separator = styled.div`
//   width: 100%;
//   height: 2px;
//   margin: 14px auto;
// `;
